import styled from "styled-components"
import { screenSizes } from "model"

export const Grid = styled.div`
  margin-top: 45px;
  width: 100%;
  max-width: 1220px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  row-gap: 60px;
  column-gap: 40px;

  @media (max-width: ${screenSizes.largeDesktop}px) {
    column-gap: 30px;
  }
  @media (max-width: ${screenSizes.bigDesktop}px) {
    column-gap: 20px;
  }
  @media (max-width: ${screenSizes.tablet}px) {
    margin-top: 40px;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto;
  }
`

export const GridItem = styled.div`
  width: 100%;
  max-width: 428px;
  display: flex;
  flex-direction: column;
  justify-self: center;

  @media (max-width: ${screenSizes.tablet}px) {
    max-width: unset;
  }
`

export const GridImage = styled.img<{ bottomMargin?: string }>`
  margin-bottom: ${({ bottomMargin }) => bottomMargin || "30px"};
  max-height: 259px;
  @media (max-width: ${screenSizes.tablet}px) {
    margin-bottom: 40px;
  }
  @media (max-width: ${screenSizes.smallMobile}px) {
    margin-bottom: 20px;
  }
`
