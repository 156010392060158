import React, { FC, ReactNode } from "react"
import styled from "styled-components"
import { colors } from "model"

export const StyledHeader = styled.h2`
  width: 100%;
  text-align: center;
`

const StyledHr = styled.hr`
  width: 100px;
  height: 2px;
  margin: 10px auto auto;
  color: ${colors.primaryDark};
  background-color: ${colors.primaryLight};
`

interface SecondaryHeaderProps {
  children: ReactNode
  className?: string
}

const SecondaryHeader: FC<SecondaryHeaderProps> = ({ children, className = "" }): JSX.Element => (
  <StyledHeader className={className}>
    {children}
    <StyledHr />
  </StyledHeader>
)

export default SecondaryHeader
